this_url = location.protocol + "//" + location.hostname + location.pathname;
// this_url = location.protocol + '//' + location.host + location.pathname;
dir_arr = location.pathname.split("/");
root_dir = dir_arr[1];
parent_dir = dir_arr[dir_arr.length - 3];
current_dir = dir_arr[dir_arr.length - 2];
this_file = dir_arr[dir_arr.length - 1];

// レスポンシブでの振り分け
function device() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (
    window.innerWidth < 769 ||
    (ua.indexOf("iPhone") > 0 && ua.indexOf("iPad") == -1) ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    return "sp";
    // } else if((window.innerWidth < 769) || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
  } else if (window.innerWidth < 992) {
    return "tb";
  } else {
    return "pc";
  }
}

// インクルード
function include(part, dir) {
  var root = "/";
  var loc = location.pathname.split("/");
  var dirName = loc[loc.length - 2];
  if (location.href.indexOf("file://") === 0) {
    if (dirName == "corporate" || dirName == "medical") {
      root = "";
    } else {
      root = "../";
    }
  }
  var file = root + "include/" + part + ".html";
  $.ajax({
    url: file,
    type: "get",
    dataType: "html",
    cache: false,
  })
    .done(function (data) {
      //ロード成功
      if (part.indexOf("header") === 0) {
        $("body").prepend(data);
      } else if (part.indexOf("sitemap") === 0) {
        if ($("body > div.site").length > 0) {
          $("body > div.site").after(data);
        } else {
          $("#main").after(data);
        }
      } else if (part.indexOf("footer") === 0) {
        $("body").append(data);
      } else if (part.indexOf("sidebar") === 0) {
        $("#article").before(data);
      }
    })
    .fail(function (data) {
      //ロード失敗
      console.log(part + " : include error");
    })
    .complete(function (data) {
      //ロード完了後

      // ヘッダーやナビの位置調整
      if (device() != "pc") {
        var headerH = $("header").outerHeight();
        $("#main").css("paddingTop", headerH);
        $("#head-nav, #search_form").css("top", headerH);
      } else {
        $("#main").css("paddingTop", 0);
        $("#head-nav, #search_form").removeAttr("style");
      }

      // サイドナビのカレント表示
      var sidebar = $("#sidebar ul");
      if ($(sidebar).length > 0) {
        $(sidebar)
          .children("li")
          .each(function () {
            var href = $(this).children("a").attr("href");
            var href_full = location.protocol + "//" + location.hostname + href;
            var href_arr = href.replace(/\/$/, "").split("/");
            var href_file = href_arr[href_arr.length - 1];
            var href_dir = href_arr[href_arr.length - 2];
            if (
              this_url == href_full ||
              this_url == href_full + "index.html" ||
              this_url + "index.html" == href_full ||
              (current_dir == href_file && parent_dir == href_dir)
            ) {
              $(this).addClass("current");
              return false;
            } else if (
              href_dir == "product" &&
              this_file.indexOf("news") === 0 &&
              href_file == "t_spot_news.html"
            ) {
              $(this).addClass("current");
              return false;
            }
          });
      }
    });
}

$(function () {
  // アンカースクロール
  $(document).on("click", 'a[href^="#"]', function (e) {
    e.preventDefault();
    var href = $(this).attr("href");
    var speed = 500;
    var dist = 20;
    if (device() != "pc") {
      dist += $("header").outerHeight();
      //  var dist = $('body').hasClass('scrolled') ? 90 : 140;
    }
    if ($(this).attr("href") == "#") {
      $("html, body").animate({ scrollTop: 0 }, speed, "swing");
    }
    if ($(this).hasClass("page-top")) {
      //トップへ戻る
      $("html, body").animate({ scrollTop: 0 }, speed, "swing");
    } else if ($(href).length) {
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top - dist;
      $("#btn-menu, #head-nav").removeClass("open");
      $("html, body").animate({ scrollTop: position }, speed, "swing");
    }
    return false;
  });

  // ヘッダーナビの下層リンク展開
  $(document).on("click", "#head-nav li.parent", function (e) {
    $(this).toggleClass("open");
  });

  /*$('#g-nav li').on({
    'mouseenter' : function(){
      $(this).addClass('animated');
    },
    'mouseleave' : function(){
      $(this).removeClass();
    }
  });*/

  // ハンバーガーメニュー展開
  $(document).on("click", "#btn-menu", function () {
    $("#btn-menu").toggleClass("open");
    $("#head-nav").toggleClass("open");
    /*if(device() == 'sp') {
      $('#head-nav').removeAttr('style');
    }*/
    return false;
  });

  // 検索窓展開
  $(document).on("click", "header .search.btn", function () {
    $("#search_form").toggleClass("open");
    return false;
  });
  $(document).on("click", "#search_form .close", function () {
    $("#search_form").removeClass("open");
    return false;
  });

  // ログインメニュー展開
  $(document).on("click", "header .logged-in .welcome", function (e) {
    e.preventDefault();
    $(this).parent(".logged-in").toggleClass("open");
    return false;
  });
  // ログインメニュー閉じる
  $("#main").on("click", function (e) {
    if ($("header .logged-in").hasClass("open")) {
      $("header .logged-in").removeClass("open");
    }
  });

  // FAQのアコーディオン展開
  $("#article.faq dt a").on("click", function (e) {
    $(this).parent("dt").toggleClass("open").next("dd").slideToggle();
    return false;
  });

  //送信確認ダイアログ
  $("section#password form").on("submit", function () {
    var confirm_message = "";
    var type = $(this).find("input").attr("type");
    if (type == "password") {
      confirm_message = "パスワードの再設定を行いますか？";
    } else if (type == "email") {
      confirm_message =
        "ご入力いただいたメールアドレスにメールを送信しますか？";
    }
    var result = window.confirm(confirm_message);
    if (result) {
      //「はい」の処理
      var send_message = "";
      if (type == "password") {
        send_message =
          "パスワードの再設定が完了しました。\n医療関係者向けサイトTOPへ戻ります。";
      } else if (type == "email") {
        send_message =
          "ご入力いただいたメールアドレスにメールを送信しました。\n医療関係者向けサイトTOPへ戻ります。";
      }
      window.alert(send_message);
      var home = location.protocol + "//" + location.hostname;
      location.href = home;
      return false;
    } else {
      //「いいえ」の処理
      return false;
    }
  });

  //もっと見るボタン
  $("#article.event .btn.more").on("click", function () {
    $(this).prev("ul").slideToggle();
    return false;
  });

  // ページトップへ戻るボタン
  $(".page-top").fadeOut(0);
});

$(window).on("scroll", function () {
  var sTop = $(window).scrollTop();
  var sLeft = $(window).scrollLeft();
  var sWin = $(document).width() - $(window).width();
  // header.fixed
  if (sTop >= 1) {
    $("body").addClass("scrolled");
  } else {
    $("body").removeClass("scrolled");
  }

  // ページトップへ戻るボタン
  var pt = $(".page-top");
  var foot_pos =
    $("#footer").offset().top -
    $(window).height() +
    $("#footer").innerHeight() / 2 +
    100;
  if ($(this).scrollTop() > 200) {
    pt.fadeIn();
    if ($(this).scrollTop() >= foot_pos && device() == "pc") {
      pt.addClass("abs");
    } else {
      pt.removeClass("abs");
    }
  } else {
    pt.fadeOut();
  }
});

$(window).on("load resize orientationchange", function (e) {
  if (device() != "pc") {
    var headerH = $("header").outerHeight();
    $("#main").css("paddingTop", headerH);
    $("#head-nav, #search_form").css("top", headerH);

    //スマホ・タブレットの場合、電話番号リンクを付ける
    $(".tel").each(function () {
      if ($(this).is("img")) {
        //画像の場合
        $(this).wrap('<a href="tel:' + $(this).attr("data-tel") + '"></a>');
      } else {
        //テキストの場合
        $(this).wrapInner('<a href="tel:' + $(this).text() + '"></a>');
      }
    });
  } else {
    $("#main").css("paddingTop", 0);
    $("#head-nav, #search_form").removeAttr("style");
  }
});

// $(function () {
//   var itemHeights = [];
//   var returnHeight;

//   if ($(".grad-trigger").length > 0) {
//     $(".grad-trigger").prev(".wap").each(function () { //ターゲット(縮めるアイテム)
//       var thisHeight = $(this).height(); //ターゲットの高さを取得
//       itemHeights.push(thisHeight); //それぞれの高さを配列に入れる
//       $(this).addClass("is-hide"); //CSSで指定した高さにする
//       returnHeight = $(this).height(); //is-hideの高さを取得
//     });
//   }

//   $(".grad-trigger").on('click', function (e) { //トリガーをクリックしたら
//     if (!$(this).hasClass("is-show")) {
//       var index = $(this).index(".grad-trigger"); //トリガーが何個目か
//       var addHeight = itemHeights[index]; //個数に対応する高さを取得
//       $(this).addClass("is-show").prev().animate({ height: addHeight }, 200).removeClass("is-hide"); //高さを元に戻す
//     } else {
//       $(this).removeClass("is-show").prev().animate({ height: returnHeight }, 200).addClass("is-hide"); //高さを制限する
//     }
//   });

// });
